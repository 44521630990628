const ID_TOKEN_KEY = "id_token" as string;
const ID_USER_KEY = "id_user" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  // window.localStorage.removeItem(ID_USER_KEY);
};

export const saveUser = (user: string): void => {
  window.localStorage.setItem(ID_USER_KEY, user);
};

export const getUser = (): any | undefined => {
  const user = window.localStorage.getItem(ID_USER_KEY);
  if (user == null) {
    return undefined;
  } else {
    return JSON.parse(user);
  }
};

export default { getToken, saveToken, destroyToken, getUser, saveUser };

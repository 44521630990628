import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface InfoTracking {
  kode_tracking: string;
  tanggal_permohonan: string;
  nama_pemohon: string;
  jenis_izin: string;
  logs: string;
}

export interface PermohonanIzin {
  id: number;
  tracking_code: string;
  pemohon_nama: string;
  pemohon_nik: string;
  pemohon_alamat: string;
  pemohon_hp: string;
}

export interface IPerizinan {
  id: number;
  nama: string;
  form: Array<DataPermohonan>;
  persyaratan: Array<DataPersyaratan>;
}

export interface ILog {
  id: number;
  module: string;
  action: string;
  user: number;
  date: string;
  notes: string;
  ip: string;
}

export interface PerizinanInfo {
  errorsResponse: unknown;
  listPermohonanBaru: Array<PermohonanIzin>;
  listPermohonanRevisi: Array<PermohonanIzin>;
  listPermohonanACC: Array<PermohonanIzin>;
  listPermohonanArsip: Array<PermohonanIzin>;
  listPermohonanCetak: Array<PermohonanIzin>;
  listDaftarIzin: Array<IPerizinan>;
  listLogs: Array<ILog>;
  selectedPermohonanIzin: PermohonanIzin;
}

export interface Template {
  id: number;
  nama: string;
  type: string;
  path: string;
  fullpath: string;
}

export interface JenisIzin {
  id: number;
  nama: string;
  form?: any;
  persyaratan?: any;
  template: Template;
}

interface RequestVerification {
  tracking_code: string;
}

export interface DataPermohonan {
  perizinanisi_id: string;
  perizinanisi_kode: string;
  perizinanisi_name: string;
  perizinanisi_type: string;
  value: string;
}

export interface DataPersyaratan {
  perizinansyarat_id: string;
  perizinansyarat_kode: string;
  perizinansyarat_name: string;
  perizinansyarat_type: string;
  value: string;
  status: string;
}

export interface DetilPermohonan {
  id: number;
  tracking_code: string;
  pemohon_nik: string;
  pemohon_nama: string;
  pemohon_hp: string;
  pemohon_alamat: string;
  tgl_permohonan: string;
  masa_berlaku: string;
  jenis_izin: JenisIzin;
  data_permohonan: DataPermohonan[];
  data_persyaratan: DataPersyaratan[];
}

export interface InformasiIzin {
  no_izin: string;
  jenis_izin: string;
  nama_pemohon: string;
  nama_penandatangan: string;
  jabatan_penandatangan: string;
  waktu_ditandatangani: string;
}

export interface IDashboardWidget {
  name: string;
  code: string;
  data: any;
}

export interface MasterIzin {
  id: number;
  nama: string;
  format_penomoran: string;
  loket: number;
  backoffice: number;
  form: Array<any>;
  persyaratan: Array<any>;
  verificator: Array<any>;
}
export interface MasterUser {
  id: number;
}

@Module
export default class PermohonanIzinModule
  extends VuexModule
  implements PerizinanInfo
{
  errorsResponse = {};
  selectedPermohonanIzin = {} as PermohonanIzin;
  selectedDetilPermohonan = {} as DetilPermohonan;
  statusVerfication = {} as InformasiIzin;
  listPermohonanBaru = Array<PermohonanIzin>();
  listPermohonanRevisi = Array<PermohonanIzin>();
  listPermohonanACC = Array<PermohonanIzin>();
  listPermohonanCetak = Array<PermohonanIzin>();
  listPermohonanArsip = Array<PermohonanIzin>();
  listDaftarIzin = Array<IPerizinan>();
  listLogs = Array<ILog>();
  listDashboard = Array<IDashboardWidget>();
  listMasterIzin = Array<MasterIzin>();
  listMasterSyarat = Array<PermohonanIzin>();
  listMasterForm = Array<any>();
  listVerificators = Array<any>();
  listUsers = Array<MasterUser>();
  infoTracking = {};
  currentSelectedIzin = {};

  get currentMasterIzin(): Array<MasterIzin> {
    return this.listMasterIzin;
  }

  get getSelectedDetailIzin(): any {
    return this.currentSelectedIzin;
  }

  get currentListMasterSyarat(): Array<any> {
    return this.listMasterSyarat;
  }

  get currentListMasterForm(): Array<any> {
    return this.listMasterForm;
  }

  get currentMasterUser(): Array<MasterUser> {
    return this.listUsers;
  }

  get currentListPermohonanBaru(): Array<PermohonanIzin> {
    return this.listPermohonanBaru;
  }

  get currentListPermohonanRevisi(): Array<PermohonanIzin> {
    return this.listPermohonanRevisi;
  }

  get currentListPermohonanACC(): Array<PermohonanIzin> {
    return this.listPermohonanACC;
  }

  get currentListVerificators(): Array<any> {
    return this.listVerificators;
  }

  get currentListCetak(): Array<PermohonanIzin> {
    return this.listPermohonanCetak;
  }

  get currentListArsip(): Array<PermohonanIzin> {
    return this.listPermohonanArsip;
  }

  get currentListDaftarIzin(): Array<IPerizinan> {
    return this.listDaftarIzin;
  }

  get currentPermohonanSelected(): PermohonanIzin {
    return this.selectedPermohonanIzin;
  }

  get currentDetailPermohonan(): DetilPermohonan {
    return this.selectedDetilPermohonan;
  }

  get getVerificationIzinStatus(): InformasiIzin {
    return this.statusVerfication;
  }

  get getInfoTracking(): any {
    return this.infoTracking;
  }

  get getErrorsResponse() {
    return this.errorsResponse;
  }

  get logErrorTTE() {
    return this.listLogs;
  }

  get currentDashboardWidgets() {
    return this.listDashboard;
  }

  @Mutation
  [Mutations.SET_PERMOHONAN_BARU](list) {
    this.listPermohonanBaru = list; //{ ...list };
  }

  @Mutation
  [Mutations.SET_PERMOHONAN_REVISI](list) {
    this.listPermohonanRevisi = list; //{ ...list };
  }

  @Mutation
  [Mutations.SET_PERMOHONAN_ACC](list) {
    this.listPermohonanACC = list; //{ ...list };
  }

  @Mutation
  [Mutations.SET_PERMOHONAN_ARSIP](list) {
    this.listPermohonanArsip = list; //{ ...list };
  }

  @Mutation
  [Mutations.SET_PERMOHONAN_CETAK](alist) {
    this.listPermohonanCetak = alist; //{ ...list };
  }

  @Mutation
  [Mutations.SET_DAFTAR_IZIN](list) {
    this.listDaftarIzin = list; //{ ...list };
  }

  @Mutation
  [Mutations.SET_SELECTED_PERMOHONAN](item) {
    this.selectedPermohonanIzin = item; //{ ...list };
  }

  @Mutation
  [Mutations.SET_VERIFICATORS](item) {
    this.listVerificators = item; //{ ...list };
  }

  @Mutation
  [Mutations.SET_INFO_TRACKING](item) {
    this.infoTracking = item; //{ ...list };
  }

  @Action
  [Actions.SET_SELECTED_PERMOHONAN](payload) {
    this.context.commit(Mutations.SET_SELECTED_PERMOHONAN, payload);
  }

  @Mutation
  [Mutations.SET_SELECTED_MASTER_IZIN](item) {
    this.currentSelectedIzin = item; //{ ...list };
  }

  @Action
  [Actions.SET_SELECTED_MASTER_IZIN](payload) {
    this.context.commit(Mutations.SET_SELECTED_MASTER_IZIN, payload);
  }

  @Mutation
  [Mutations.SET_SELECTED_DETIL_PERMOHONAN](item) {
    this.selectedDetilPermohonan = item; //{ ...list };
  }

  @Mutation
  [Mutations.SET_ERROR_REQUEST](error) {
    this.errorsResponse = { ...error };
  }

  @Mutation
  [Mutations.SET_INFO_VERIFY_IZIN](payload) {
    this.statusVerfication = payload;
  }

  @Mutation
  [Mutations.SET_DETAIL_IZIN](payload) {
    this.currentSelectedIzin = payload;
  }

  @Mutation
  [Mutations.SET_LOG_TTE](payload) {
    this.listLogs = payload;
  }

  @Mutation
  [Mutations.SET_DASHBOARD](payload) {
    this.listDashboard = payload;
  }

  @Mutation
  [Mutations.UPDATE_SYARAT](payload) {
    const { id, result } = payload;
    this.selectedDetilPermohonan.data_persyaratan.forEach(function (value) {
      if (value.perizinansyarat_id == id) {
        value.value = result.data;
        value.status = "true";
      }
    });
  }

  @Mutation
  [Mutations.SET_MASTER_IZIN](list) {
    this.listMasterIzin = list;
  }

  @Mutation
  [Mutations.SET_MASTER_PERYARATAN](list) {
    this.listMasterSyarat = list; //{ ...list };
  }

  @Mutation
  [Mutations.SET_MASTER_FORM](list) {
    this.listMasterForm = list; //{ ...list };
  }

  @Action
  [Actions.GET_PERMOHONAN_BARU](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.query("v1/permohonan/baru", payload)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_PERMOHONAN_BARU, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };

            // this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_PERMOHONAN_ARSIP](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("v1/permohonan", "arsip")
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_PERMOHONAN_ARSIP, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };

            // this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_PERMOHONAN_CETAK](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("v1/permohonan", "cetak")
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_PERMOHONAN_CETAK, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };

            // this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_PERMOHONAN_DETAIL](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.query(`v1/permohonan`, {
        params: {
          ID: payload,
        },
      })
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(
              Mutations.SET_SELECTED_DETIL_PERMOHONAN,
              data.data
            );
          } else {
            const error = {
              errormessage: [data.message],
            };
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_DETAIL_IZIN](payload) {
    const { id } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(`v1/master/izin`, id)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_DETAIL_IZIN, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_PERMOHONAN_REVISI](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("v1/permohonan", "revisi")
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_PERMOHONAN_REVISI, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_PERMOHONAN_ACC](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("v1/permohonan", "acc")
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_PERMOHONAN_ACC, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };

            // this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_PERMOHONAN_TTD](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("v1/permohonan", "ttd")
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_PERMOHONAN_ACC, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };

            // this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_DAFTAR_IZIN](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.query("v1/perizinan", {})
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_DAFTAR_IZIN, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };

            // this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.SEND_PERMOHONAN_BARU](payload) {
    const { data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`v1/permohonan`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR_REQUEST, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR_REQUEST, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR_REQUEST, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.ADD_MASTER_FORM](payload) {
    const { data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`v1/master/form_izin`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR_REQUEST, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR_REQUEST, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR_REQUEST, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.ADD_MASTER_PERSYARATAN](payload) {
    const { data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`v1/master/persyaratan_izin`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR_REQUEST, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR_REQUEST, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR_REQUEST, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.ADD_PERIZINAN](payload) {
    const { data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`v1/master/izin`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR_REQUEST, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR_REQUEST, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR_REQUEST, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.ADD_FORM_TO_IZIN](payload) {
    const { id, data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`v1/master/izin/${id}/form`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.statusText],
          };

          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.ADD_SYARAT_TO_IZIN](payload) {
    const { id, data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`v1/master/izin/${id}/syarat`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.statusText],
          };

          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.ADD_VERIFIKATOR_TO_IZIN](payload) {
    const { id, data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`v1/master/izin/${id}/verificator`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.statusText],
          };

          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_CETAK](payload) {
    const { id, data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`v1/permohonan/${id}/cetak`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR_REQUEST, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR_REQUEST, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR_REQUEST, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.APPROVE_PERMOHONAN](payload) {
    const { id, data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`v1/permohonan/${id}/acc`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.REVISI_PERMOHONAN](payload) {
    const { id, data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`v1/permohonan/${id}/revisi`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.VERIFY_IZIN](payload) {
    const { kode_tracking } = payload;

    return ApiService.post(`v1/perizinan/verification`, {
      data: {
        tracking_code: kode_tracking,
      },
    })
      .then(({ data }) => {
        if (data.status == "OK") {
          this.context.commit(Mutations.SET_ERROR, {});
          this.context.commit(Mutations.SET_INFO_VERIFY_IZIN, data.data);
        } else {
          const error = {
            errormessage: [data.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        }
      })
      .catch(({ response }) => {
        const error = {
          errormessage: [response.message],
        };
        this.context.commit(Mutations.SET_ERROR, error);
      });
  }

  @Action
  [Actions.TTD_PERMOHONAN](payload) {
    const { id, data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`v1/permohonan/${id}/ttd`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_PERMOHONAN](payload) {
    const { id, data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`v1/permohonan/${id}/update`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_PERIZINAN](payload) {
    const { id, data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`v1/master/izin/${id}`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_NO_IZIN](payload) {
    const { id, data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`v1/master/izin/${id}/nomor`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPLOAD_SYARAT](payload) {
    const { id, idsyarat, data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeaderUpload();
      // /v1/permohonan/persyaratan/:idpermohonan/:idsyarat
      return ApiService.post(
        `v1/permohonan/persyaratan/${id}/${idsyarat}`,
        data
      )
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
            //update syarat
            this.context.commit(Mutations.UPDATE_SYARAT, {
              id: idsyarat,
              result: data,
            });
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPLOAD_TEMPLATE](payload) {
    const { id, data } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeaderUpload();
      return ApiService.post(`v1/master/izin/${id}/template`, data)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.statusText],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.DELETE_RESOURCE](payload) {
    const { id } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeaderUpload();
      return ApiService.delete(`v1/resources/${id}/delete`)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.DELETE_FORM_FROM_IZIN](payload) {
    const { id, perizinanId } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeaderUpload();
      return ApiService.delete(`v1/master/izin/${perizinanId}/form/${id}`)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.DELETE_SYARAT_FROM_IZIN](payload) {
    const { id, perizinanId } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeaderUpload();
      return ApiService.delete(`v1/master/izin/${perizinanId}/syarat/${id}`)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.DELETE_VERIFIKATOR_FROM_IZIN](payload) {
    const { id, perizinanId } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeaderUpload();
      return ApiService.delete(
        `v1/master/izin/${perizinanId}/verificator/${id}`
      )
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.DELETE_PERMOHONAN](payload) {
    const { id } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeaderUpload();
      ApiService.delete(`v1/permohonan/${id}`)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.DELETE_TEMPLATE](payload) {
    const { id } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeaderUpload();
      ApiService.delete(`v1/master/izin/${id}/template`)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_LOG_TTE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("v1/logs", "tte")
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_LOG_TTE, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };

            // this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.DELETE_SYARAT](payload) {
    const { id, idsyarat } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeaderUpload();
      return ApiService.delete(
        `v1/permohonan/persyaratan/${id}/${idsyarat}/delete`
      )
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.DELETE_PERIZINAN](payload) {
    const { id } = payload;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(`v1/master/izin/${id}`)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            const error = {
              errormessage: [data.message],
            };
            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_DASHBOARD_STAT](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.query("v1/user/dashboard", payload)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_DASHBOARD, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };

            // this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  //master perizinan

  @Action
  [Actions.GET_ALL_MASTER_PERIZINAN](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("v1/master/izin", payload)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_MASTER_IZIN, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };

            this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_MASTER_FORM](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("v1/master/form_izin", payload)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_MASTER_FORM, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };

            // this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_MASTER_PERSYARATAN](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("v1/master/persyaratan_izin", payload)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_MASTER_PERYARATAN, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };

            // this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_ALL_VERIFICATOR](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.query("v1/master/verificator", {})
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_VERIFICATORS, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };

            // this.context.commit(Mutations.SET_ERROR, error);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.statusText],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.TRACK_IZIN](payload) {
    const { kode_tracking } = payload;

    return ApiService.post(`v1/tracking?kode_tracking=${kode_tracking}`, {
      data: {},
    })
      .then(({ data }) => {
        if (data.status == "OK") {
          console.log(data.data);
          this.context.commit(Mutations.SET_ERROR, {});
          this.context.commit(Mutations.SET_INFO_TRACKING, data.data);
        } else {
          const error = {
            errormessage: [data.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
        }
      })
      .catch(({ response }) => {
        const error = {
          errormessage: [response.message],
        };
        this.context.commit(Mutations.SET_ERROR, error);
      });
  }
}

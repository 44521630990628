import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { User } from "@/core/data/users";

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user: User = {
    name: "",
    api_token: "",
    email: "",
    password: "",
    surname: "",
    usergroup: { id: 0, nama: "ok" },
  };

  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    const u: User = JwtService.getUser();
    return u;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    JwtService.saveToken(user.api_token);
    JwtService.saveUser(JSON.stringify(this.user));
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
    JwtService.saveUser(JSON.stringify(this.user));
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    JwtService.destroyToken();
    this.user = {} as User;
    this.errors = {};
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("v1/login", credentials)
      .then(({ data }) => {
        if (data.status == "OK") {
          this.context.commit(Mutations.SET_AUTH, data.data);
        } else {
          const error = {
            errormessage: [data.message],
          };

          this.context.commit(Mutations.SET_ERROR, error);
        }
      })
      .catch(({ response }) => {
        const error = {
          errormessage: [response.message],
        };

        this.context.commit(Mutations.SET_ERROR, error);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    return ApiService.post("v1/logout", {})
      .then(({ data }) => {
        if (data.status == "OK") {
          this.context.commit(Mutations.PURGE_AUTH);
        } else {
          const error = {
            errormessage: [data.message],
          };

          this.context.commit(Mutations.SET_ERROR, error);
        }
      })
      .catch(({ response }) => {
        const error = {
          errormessage: [response.message],
        };
        this.context.commit(Mutations.SET_ERROR, error);
      });
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("v1/register", credentials)
      .then(({ data }) => {
        if (data.status == "OK") {
          this.context.commit(Mutations.SET_AUTH, data.data);
        } else {
          const error = {
            errormessage: [data.message],
          };

          this.context.commit(Mutations.SET_ERROR, error);
        }
      })
      .catch(({ response }) => {
        const error = {
          errormessage: [response.message],
        };
        this.context.commit(Mutations.SET_ERROR, error);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("v1/forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        const error = {
          errormessage: [response.message],
        };
        this.context.commit(Mutations.SET_ERROR, error);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("v1/verify_token", payload)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.context.commit(Mutations.SET_AUTH, data.data);
          } else {
            const error = {
              errormessage: [data.message],
            };

            this.context.commit(Mutations.SET_ERROR, error);
            this.context.commit(Mutations.PURGE_AUTH);
          }
        })
        .catch(({ response }) => {
          const error = {
            errormessage: [response.message],
          };
          this.context.commit(Mutations.SET_ERROR, error);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}

enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",

  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  //perizinan
  SEND_PERMOHONAN_BARU = "sendPermohonanBaru",
  GET_PERMOHONAN_BARU = "getListPermohonanBaru",
  GET_PERMOHONAN_REVISI = "getListPermohonanRevisi",
  GET_PERMOHONAN_ACC = "getListPermohonanACC",
  GET_PERMOHONAN_TTD = "getListPermohonanTTD",
  GET_PERMOHONAN_ARSIP = "getListArsip",
  GET_PERMOHONAN_CETAK = "getListCetak",
  GET_LOG_TTE = "getLogTTE",
  UPDATE_PERMOHONAN = "updatePermohonan",
  UPLOAD_SYARAT = "uploadSyarat",
  DELETE_SYARAT = "deleteSyarat",
  DELETE_RESOURCE = "deleteResource",
  APPROVE_PERMOHONAN = "approvePermohonan",
  DELETE_PERMOHONAN = "deletePermohonan",
  REVISI_PERMOHONAN = "revisiPermohonan",
  TTD_PERMOHONAN = "signPermohonan",
  TTD_PERMOHONAN_MASSAL = "signPermohonanMassal",
  REJECT_PERMOHONAN = "rejectPermohonan",
  GET_PERMOHONAN_DETAIL = "getPermohonan",
  GET_DAFTAR_IZIN = "getDaftarIzin",
  GET_MY_PERMOHONAN = "getDaftarPermohonanUser",
  GET_MY_IZIN = "getDaftarIzinUser",
  SET_SELECTED_PERMOHONAN = "setSelectedPermohonan",
  SET_SELECTED_MASTER_IZIN = "ActionsetSelectedMasterIzin",

  VERIFY_IZIN = "getVerificationIzin",
  TRACK_IZIN = "getTrackingIzin",
  UPDATE_CETAK = "updateCetak",
  //users
  GET_ALL_USERS = "getAllUsers",
  ADD_USER = "addUSER",
  DELETE_USER = "deleteUser",
  UPDATE_USER = "updateUser",
  VIEW_USER = "viewUser",
  //perizinan
  GET_ALL_MASTER_PERIZINAN = "getAllIzin",
  GET_DETAIL_IZIN = "getDetailzin",
  ADD_PERIZINAN = "addPerizinan",
  UPDATE_PERIZINAN = "updatePerizinan",
  UPDATE_NO_IZIN = "updateNoPerizinan",
  DELETE_PERIZINAN = "deletePerizinan",
  VIEW_PERIZINAN = "viewPerizinan",
  //statistik
  GET_DASHBOARD_STAT = "getDashboardStatistic",
  //master data
  ADD_MASTER_PERSYARATAN = "addMasterPersyaratan",
  GET_MASTER_PERSYARATAN = "getMasterPersyaratan",
  ADD_MASTER_FORM = "ADD_MASTER_FORM",
  GET_MASTER_FORM = "GET_MASTER_FORM",
  GET_ALL_VERIFICATOR = "GET_ALL_VERIFICATOR",
  UPLOAD_TEMPLATE = "UPLOAD_TEMPLATE",
  DELETE_TEMPLATE = "DELETE_TEMPLATE",
  ADD_FORM_TO_IZIN = "ADD_FORM_TO_IZIN",
  ADD_SYARAT_TO_IZIN = "ADD_SYARAT_TO_IZIN",
  ADD_VERIFIKATOR_TO_IZIN = "ADD_VERIFIKATOR_TO_IZIN",
  DELETE_FORM_FROM_IZIN = "DELETE_FORM_FROM_IZIN",
  DELETE_SYARAT_FROM_IZIN = "DELETE_SYARAT_FROM_IZIN",
  DELETE_VERIFIKATOR_FROM_IZIN = "DELETE_VERIFIKATOR_FROM_IZIN",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_ERROR_REQUEST = "setErrorsRequest",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  //perizinan store
  SET_PERMOHONAN_BARU = "setListPermohonanBaru",
  SET_PERMOHONAN_REVISI = "setListPermohonanRevisi",
  SET_PERMOHONAN_ACC = "setListPermohonanACC",
  SET_PERMOHONAN_ARSIP = "setListPermohonanArsip",
  SET_PERMOHONAN_CETAK = "setListPermohonanCetak",
  SET_DAFTAR_IZIN = "SET_DAFTAR_IZIN",
  SET_SELECTED_PERMOHONAN = "setSelectedPermohonanMutation",
  SET_SELECTED_DETIL_PERMOHONAN = "setSelectedDetilPermohonanMutation",
  SET_SELECTED_MASTER_IZIN = "setSelectedMasterIzin",
  UPDATE_SYARAT = "updateSyarat",
  SET_INFO_VERIFY_IZIN = "setVerificationIzin",
  SET_INFO_TRACKING = "setTrackingInfo",
  SET_LOG_TTE = "setListLogTTE",
  SET_DASHBOARD = "setDashbboardStat",
  SET_MASTER_IZIN = "SET_MASTER_IZIN",
  //master
  SET_MASTER_PERYARATAN = "SET_MASTER_PERSYARATAN",
  SET_MASTER_FORM = "SET_MASTER_FORM",
  SET_DETAIL_IZIN = "SET_DETAIL_IZIN",
  SET_VERIFICATORS = "SET_VERIFICATORS",
}

export { Actions, Mutations };

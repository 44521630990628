import {
  createRouter,
  // createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/dashboard/DashboardAdmin.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/perizinan/list_baru",
        name: "perizinan-daftar-permohonan",
        component: () =>
          import("@/views/perizinan/permohonan/DaftarPermohonan.vue"),
        meta: {
          pageTitle: "Daftar Permohonan Baru",
          breadcrumbs: ["Perizinan"],
        },
      },
      {
        path: "/perizinan/list_online",
        name: "perizinan-daftar-permohonan-online",
        component: () =>
          import("@/views/perizinan/permohonan/DaftarPermohonanOnline.vue"),
        meta: {
          pageTitle: "Daftar Permohonan Online",
          breadcrumbs: ["Perizinan"],
        },
      },
      {
        path: "/perizinan/list_revisi",
        name: "perizinan-daftar-permohonan-revisi",
        component: () =>
          import("@/views/perizinan/permohonan/DaftarPermohonanRevisi.vue"),
        meta: {
          pageTitle: "Daftar Permohonan di Revisi",
          breadcrumbs: ["Perizinan"],
        },
      },
      {
        path: "/perizinan/list_perizinan",
        name: "perizinan-daftar",
        component: () => import("@/views/perizinan/DaftarPerizinan.vue"),
        meta: {
          pageTitle: "Daftar Perizinan",
          breadcrumbs: ["Perizinan"],
        },
      },
      {
        path: "/perizinan/list_cetak",
        name: "perizinan-cetak",
        component: () => import("@/views/perizinan/permohonan/DaftarCetak.vue"),
        meta: {
          pageTitle: "Daftar Perizinan Menunggu Cetak",
          breadcrumbs: ["Perizinan"],
        },
      },
      {
        path: "/perizinan/list_expire",
        name: "perizinan-expired",
        component: () => import("@/views/perizinan/DaftarPerizinanExpired.vue"),
        meta: {
          pageTitle: "Daftar Perizinan Akan Habis Masa Berlaku",
          breadcrumbs: ["Perizinan"],
        },
      },
      {
        path: "/perizinan/list_arsip",
        name: "perizinan-arsip",
        component: () => import("@/views/perizinan/Arsip.vue"),
        meta: {
          pageTitle: "Daftar Arsip Perizinan Terbit",
          breadcrumbs: ["Perizinan"],
        },
      },
      {
        path: "/perizinan/:id/resume",
        name: "perizinan-overview",
        props: true,
        component: () =>
          import("@/views/perizinan/permohonan/OverviewPermohonan.vue"),
        meta: {
          pageTitle: "Ringkasan Permohonan",
          breadcrumbs: ["Perizinan"],
        },
      },
      {
        path: "/perizinan/list_ttd",
        name: "perizinan-permohonan-ttd",
        component: () =>
          import("@/views/perizinan/permohonan/DaftarPermohonanTtd.vue"),
        meta: {
          pageTitle: "Daftar Permintaan Tanda Tangan",
          breadcrumbs: ["Perizinan"],
        },
      },
      {
        path: "/perizinan/list_acc",
        name: "perizinan-permohonan-acc",
        component: () =>
          import("@/views/perizinan/permohonan/DaftarPermohonanAcc.vue"),
        meta: {
          pageTitle: "Daftar Permintaan ACC Permohonan",
          breadcrumbs: ["Perizinan"],
        },
      },
      {
        path: "/pengaduan/list_disposisi",
        name: "pengaduan-masuk",
        component: () => import("@/views/pengaduan/DaftarPengaduan.vue"),
        meta: {
          pageTitle: "Daftar Pengaduan",
          breadcrumbs: ["Perizinan"],
        },
      },
      {
        path: "/logs/tte",
        name: "log-tte",
        component: () => import("@/views/logs/LogTte.vue"),
        meta: {
          pageTitle: "Log TTE",
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/master",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/master/izin",
        name: "master-izin",
        component: () => import("@/views/master/izin/DaftarIzin.vue"),
        meta: {
          pageTitle: "Master Data Izin",
          breadcrumbs: ["Master Data", "Izin"],
        },
      },
      {
        path: "/master/izin/:id/detail",
        name: "master-izin-detail",
        props: true,
        component: () => import("@/views/master/izin/DetailIzin.vue"),
        meta: {
          pageTitle: "Detil Izin",
          breadcrumbs: ["Master", "Izin", "Detil"],
        },
      },
      {
        path: "/master/form",
        name: "master-form",
        component: () => import("@/views/master/izin/MetaDataIzin.vue"),
        meta: {
          pageTitle: "Master Data Form",
          breadcrumbs: ["Master Data", "Form"],
        },
      },
      {
        path: "/master/persyaratan",
        name: "master-syarat",
        component: () => import("@/views/master/izin/PersyaratanIzin.vue"),
        meta: {
          pageTitle: "Master Data Persyaratan",
          breadcrumbs: ["Master Data", "Persyaratan"],
        },
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        path: "/verifikasi",
        name: "verifikasi",
        component: () =>
          import("@/views/crafted/authentication/VerificationIzin.vue"),
        meta: {
          pageTitle: "Verifikasi Dokumen Izin",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        path: "/tracking",
        name: "tracking",
        component: () =>
          import("@/views/perizinan/permohonan/TrackingPermohonan.vue"),
        meta: {
          pageTitle: "Tracking Permohonan Izin",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Daftar Akun",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
